<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="$t('page.add')"
    :visible.sync="visible"
    center
    width="300px"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="auto" size="small">
      <el-form-item :label="$t('page.senderId')" prop="name">
        <el-input
          v-model="form.name"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
        />
      </el-form-item>
      <el-form-item :label="$t('title.mailbox')" prop="emailAddress">
        <el-input
          v-model="form.emailAddress"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
        />
      </el-form-item>
      <el-form-item :label="$t('order.types')" prop="emailType">
        <el-select
          v-model="form.emailType"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="handleClose">
        {{ $t('order.Cancel') }}
      </el-button>
      <el-button size="small" type="primary" @click="handleSubmit">
        {{ $t('title.submit') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addBlockingMailbox } from './apis/addDialog'

export default {
  name: 'AddDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    typeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        name: '',
        emailAddress: '',
        emailType: ''
      },
      rules: {
        name: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' }
        ],
        emailAddress: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'blur' }
        ],
        emailType: [
          { required: true, message: this.$t('page.selectPlaceholder'), trigger: 'blur' }
        ]
      },
      submitLoading: false
    }
  },
  methods: {
    handleClose() {
      this.form = {
        name: '',
        emailAddress: '',
        emailType: ''
      }
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
            closeOnClickModal: false,
            type: 'warning',
            beforeClose: async(action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = instance.cancelButtonLoading = true
                const { msg } = await addBlockingMailbox(this.form).finally(() => {
                  instance.confirmButtonLoading = instance.cancelButtonLoading = false
                })
                this.$message.success(msg)
                done()
                this.$emit('refresh')
                this.handleClose()
              } else {
                done()
              }
            }
          }).catch(() => {})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
