<template>
  <div>
    <el-form ref="form" :model="form" inline label-width="auto" size="small">
      <el-form-item :label="$t('page.senderId')">
        <el-select
          v-model="form.name"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          collapse-tags
          filterable
          multiple
        >
          <el-option
            v-for="item in senderList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('title.mailbox')">
        <el-select
          v-model="form.emailAddress"
          :placeholder="$t('page.inputPlaceholder')"
          clearable
          collapse-tags
          filterable
          multiple
        >
          <el-option
            v-for="item in emailList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('order.types')">
        <el-select
          v-model="form.emailType"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button :loading="queryLoading" type="primary" @click="queryClick">{{ $t('page.search') }}</el-button>
        <el-button @click="handleReset">{{ $t('page.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar size="small">
      <template #buttons>
        <el-button size="small" type="primary" @click="addDialogVisible = true">{{ $t('page.add') }}</el-button>
      </template>
    </vxe-toolbar>
    <el-table
      ref="table"
      v-loading="queryLoading"
      :data="tableData"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      :max-height="maxHeight"
      border
      class="mb-3"
      size="small"
    >
      <el-table-column
        :label="$t('page.senderId')"
        align="center"
        prop="name"
        sortable
      />
      <el-table-column
        :label="$t('title.EmailAccount')"
        align="center"
        prop="emailAddress"
        sortable
      />
      <el-table-column
        :label="$t('order.types')"
        align="center"
        prop="emailType"
        sortable
      >
        <template #default="{row}">
          {{ typeMap[row.emailType] }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('title.Blockedtime')"
        align="center"
        prop="createdTime"
        sortable
        width="160"
      />
      <el-table-column :label="$t('page.status')" align="center" width="100">
        <template #default="{row}">
          <el-switch
            :active-value="0"
            :inactive-value="1"
            :value="row.delFlag"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="val => handleUpdateStatus(row, val)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      ref="pager"
      :current-page="pager.current"
      :page-size="pager.size"
      :page-sizes="[10, 50, 100, 200, 300,500, 1000]"
      :total="pager.total"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <add-dialog
      :type-list="typeList"
      :visible.sync="addDialogVisible"
      @refresh="queryClick"
    />
  </div>
</template>

<script>
import mixin from '@/mixin/oms-mixin'
import { deleteShopifyShieldEmail, getShopifyShieldEmail } from '@/api/service-management'
import { typeList, typeMap } from './const'
import { debounceGetTableMaxHeight } from '@/utils'
import AddDialog from './AddDialog.vue'
import { getEmailList } from '@/views/service-manage/Buyer-mail-message/Block-emails/apis'

export default {
  components: { AddDialog },
  mixins: [mixin],
  data() {
    return {
      pager: {
        current: 1,
        size: 1000,
        total: 0
      },
      maxHeight: 500,
      queryLoading: false,
      tableData: [],
      form: {
        name: [],
        emailAddress: [],
        emailType: ''
      },
      formList: [],
      addDialogVisible: false
    }
  },
  computed: {
    typeList() {
      return typeList
    },
    typeMap() {
      return typeMap
    },
    queryParams() {
      return {
        ...this.form,
        current: this.pager.current,
        size: this.pager.size
      }
    },
    senderList() {
      return [...new Set(this.formList.map(item => item.name))]
    },
    emailList() {
      return [...new Set(this.formList.map(item => item.emailAddress))]
    }
  },
  created() {
    this.getEmailInfoList()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
    this._getShopifyShieldEmail()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    handleUpdateStatus(row, status) {
      this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
        type: 'warning',
        closeOnClickModal: false,
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.cancelButtonLoading = instance.confirmButtonLoading = true
            const { msg } = await deleteShopifyShieldEmail({
              ...row,
              delFlag: status
            }).finally(() => {
              instance.cancelButtonLoading = instance.confirmButtonLoading = false
            })
            this.$message.success(msg)
            done()
            await this._getShopifyShieldEmail()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    async getEmailInfoList() {
      const { datas } = await getEmailList(this.form)
      this.formList = datas || []
    },
    queryClick() {
      this.pager.current = 1
      this._getShopifyShieldEmail()
    },
    handleReset() {
      this.form = {
        name: '',
        emailAddress: '',
        emailType: ''
      }
      this.queryClick()
    },
    // 默认查询
    async _getShopifyShieldEmail() {
      try {
        this.queryLoading = true
        const { datas } = await getShopifyShieldEmail(this.queryParams)
        this.tableData = datas?.records || []
        this.pager.total = datas?.pager.total
        await this.getEmailInfoList()
      } finally {
        this.queryLoading = false
      }
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._getShopifyShieldEmail()
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getShopifyShieldEmail()
    }
  }
}
</script>

<style lang="scss" scoped>
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
