import i18n from '@/lang'
import { generateListByMap } from '@/utils'

export const statusMap = {
  0: i18n.t('page.disable'),
  1: i18n.t('page.enabled')
}

export const { list: statusList } = generateListByMap(statusMap)

export const typeMap = {
  0: i18n.t('page.DoNotReceiveMail'),
  1: i18n.t('page.DoNotSendQuestionnaire')
}

export const { list: typeList } = generateListByMap(typeMap)
